<template>
  <div>
    <span @click="openExplorerDialog" class="explorer-link">{{ computedExplorerName }}</span>
    <LoadingDialog v-model="loading"/>
    <div v-if="dialog">
      <ExplorerDialog v-model="dialog" :explorer-data="explorerData" :explorer-status="explorerStatus"/>
    </div>
  </div>
</template>

<script>
import ExplorerDialog from '@/components/specific/ExplorerDialog';
import LoadingDialog from '@/components/basic/LoadingDialog';

export default {
  name: 'ExplorerLink',
  components: {
    LoadingDialog,
    ExplorerDialog,
  },
  props: {
    row: Object,
    explorerName: String,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      explorerData: null,
      explorerStatus: null,
    };
  },
  computed: {
    computedExplorerName() {
      return this.explorerName ? this.explorerName : this.row.c_subscriber;
    },
  },
  methods: {
    openExplorerDialog() {
      this.loading = true;
      this.services.explorerStatus.getExplorerByName(this.computedExplorerName, (explorerData) => {
        this.services.explorerStatus.getStatus(this.computedExplorerName, (explorerStatus) => {
          this.explorerData = explorerData;
          this.explorerStatus = explorerStatus;
          this.dialog = true;
          this.loading = false;
        });
      });
    },
  },
};
</script>

<style scoped>
.explorer-link {
  cursor: pointer;
  color: var(--v-anchor-base);
}
</style>